import { useCookies } from "react-cookie";
import cookiesObject from "./cookiesObject";
import CookieTabs from "./CookieTabs";
import CookieText from "./CookieText";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import CookieDetails from "./CookieDetails";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import CookieIcon from "@mui/icons-material/Cookie";

const CookieConsentBanner = ({}) => {
  const cookies = new Cookies();
  const [cookieOptions, setCookieOptions] = useState({ necessary: true });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const currentCookies = cookies.getAll();
    if (!currentCookies.isSet) setOpen(true);
  }, []);

  // button functionality
  function allowAllCookies() {
    cookies.set("isSet", true, { path: "/" });
    cookies.set("allowAll", true, { path: "/" });
    setOpen(false);
  }

  function allowSelectedCookies() {
    cookies.set("isSet", true, { path: "/" });
    cookies.set("allowAll", false, { path: "/" });
    Object.keys(cookieOptions).map((key, index) =>
      cookies.set(key, cookieOptions[key], { path: "/" })
    );
    setOpen(false);
  }

  function denyAllCookies() {
    delAllCookies();
    cookies.set("isSet", true, { path: "/" });
    setOpen(false);
  }

  // handle changes
  function handleCookieChange(key, value) {
    setCookieOptions({ ...cookieOptions, [key]: value });
  }

  function delAllCookies() {
    const cookies = new Cookies();
    const currentCookies = cookies.getAll();
    for (const [key, value] of Object.entries(currentCookies)) {
      cookies.remove(key);
    }
  }

  const tabsDemo = [
    {
      label: cookiesObject.consent.title,
      content: <CookieText elements={cookiesObject.consent.text} />,
    },
    {
      label: cookiesObject.details.title,
      content: (
        <CookieDetails
          elements={cookiesObject.details.elements}
          cookieOptions={cookieOptions}
          onChange={handleCookieChange}
        />
      ),
    },
    {
      label: cookiesObject.description.title,
      content: (
        <Box className=" flex flex-col gap-3">
          <CookieText elements={cookiesObject.description.text} />
          <Link href={cookiesObject.description.dataPrivacy.href}>
            <Button className="flex flex-row gap-2">
              {cookiesObject.description.dataPrivacy.title}
              <OpenInNewIcon />
            </Button>
          </Link>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Dialog open={open}>
        <Box
          className="flex flex-col overflow-hidden justify-between pb-2"
          sx={{
            height: "80vh",
            width: "700px",
            "@media (max-width: 900px)": {
              width: "95vw",
            },
          }}
        >
          <Box className="flex flex-col h-full overflow-hidden">
            <Typography
              variant="h2"
              textAlign="center"
              className="py-8 md:py-4 relative flex flex-row gap-2 justify-center items-center"
            >
              <CookieIcon sx={{ fontSize: "30px" }} /> Cookies
              <Link
                className="absolute bottom-2 right-2"
                href="https://das-innovations.de"
                target="_blank"
              >
                <Typography>Powered by DAS Innovations</Typography>
              </Link>
            </Typography>
            <Box className="h-full flex overflow-hidden">
              <CookieTabs tabs={tabsDemo} />
            </Box>
          </Box>
          <Box
            className="px-2 grid grid-cols-3 gap-2"
            sx={{
              "& button": { padding: "10px 20px" },
            }}
          >
            <Button onClick={denyAllCookies} variant="outlined">
              Ablehnen
            </Button>
            <Button onClick={allowSelectedCookies} variant="outlined">
              Auswahl erlauben
            </Button>
            <Button
              onClick={allowAllCookies}
              color="success"
              variant="contained"
            >
              Alle zulassen
            </Button>
          </Box>
        </Box>
      </Dialog>
      <IconButton
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ position: "fixed" }}
        className="bottom-2 right-2 z-40"
      >
        <CookieIcon sx={{ fontSize: "40px" }} />
      </IconButton>
    </>
  );
};

export default CookieConsentBanner;
