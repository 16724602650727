import { Box, Typography, useTheme } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import HouseIcon from "@mui/icons-material/House";
import ImageIcon from "@mui/icons-material/Image";
import { tokens } from "../../theme/theme";
import PersonIcon from "@mui/icons-material/Person";

const StepByStep = () => {
  const steps = [
    {
      number: "1",
      title: "Vorhaben",
      text: "Erklären Sie kurz Ihr Vorhaben.",
      icon: <HouseIcon />,
    },

    {
      number: "2",
      title: "Kontaktdaten",
      text: "Um ein bestmögliches Bild von Ihrem Vorhaben zu erlangen, bitten wir Sie uns ein paar Bilder von Ihrem Vorhaben in der Mail mitzusenden.",
      icon: <PersonIcon />,
    },

    {
      number: "3",
      title: "Bilder / Pläne",
      text: "Um einen Kostenvoranschlag/Angebot erstellen zu können benötigen wir von Ihnen den vollen Namen oder Firma, die Anschrift sowie eine Telefonnummer, dass wir mit Ihnen für Rückfragen in Kontakt treten können.",
      icon: <ImageIcon />,
    },
    {
      number: "4",
      title: "Mail",
      text: "Senden Sie uns bitte eine Mail mit den Daten zu. Wir treten anschließend mit Ihnen in Kontakt.",
      icon: <EmailIcon />,
    },
  ];

  return (
    <Box className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-4 px-5 md:px-0 py-10">
      {steps.map((step, index) => (
        <Step
          key={index}
          icon={step.icon}
          title={step.title}
          text={step.text}
          number={step.number}
        />
      ))}
    </Box>
  );
};

const Step = ({ icon, title, text, number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const size = "80px";

  return (
    <Box className="flex flex-col lg:flex-row p-4 gap-2 md:gap-5 tems-center md:items-center">
      <Box
        className="flex justify-center items-center rounded-full relative "
        sx={{
          backgroundColor: colors.card,
          minWidth: size,
          maxWidth: size,
          minHeight: size,
          maxHeight: size,
          ">svg": {
            fontSize: `calc(${size} / 1.6)`,
            color: colors.primary[500],
          },
        }}
      >
        <Box className="absolute -top-3 -left-3 text-4xl font-bold">
          {number}
        </Box>
        {icon}
      </Box>
      <Typography variant="h3" className=" text-center md:text-left uppercase">
        {title}
      </Typography>
    </Box>
  );
};

export default StepByStep;
