import { Box } from "@mui/material";
import ImageWithFallback from "./ImageWithFallback";

const TitlePic = ({ title, upperText, src, height = "35vh", noText }) => {
  return (
    <Box
      className="w-full h-full pl-10 py-8 relative flex flex-col items-start justify-center"
      sx={{
        height: height ? height : "auto",
      }}
    >
      <Box className=" w-full h-full absolute top-0 left-0 z-0 ">
        <ImageWithFallback
          alt={title}
          webp={src.webp}
          jpg={src.jpg}
          className=" w-full h-full object-cover"
        />
        {!noText && (
          <Box
            className=" w-full h-full absolute top-0 left-0"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
          ></Box>
        )}
      </Box>
      <Box
        className="z-10 text-4xl md:text-6xl py-7 pl-6 drop-shadow-md"
        sx={{
          color: "white",
          textTransform: "uppercase",
          borderLeft: !noText ? "1px solid white" : "0px solid white",
        }}
      >
        {!noText && upperText && (
          <Box
            sx={{
              color: "white",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          >
            {upperText}
          </Box>
        )}
        {!noText && title}
      </Box>
    </Box>
  );
};

export default TitlePic;
