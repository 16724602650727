import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { tokens } from "../theme/theme";

const InfoTitle = ({ title, mobileTitle, subTitle, number = 1 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="flex flex-col justify-start pb-2">
      <Box
        className="text-4xl md:text-6xl font-bold relative flex justify-center items-center w-24 md:w-36 py-4"
        sx={{
          "::before, ::after": {
            width: "100%",
            height: "5px",
            content: '" "',
            backgroundColor: colors.highlight[500],
            display: "block",
            position: "absolute",
            left: "0",
          },
          "::before": {
            top: "0",
          },
          "::after": {
            bottom: "0",
          },
        }}
      >
        {number.toString().padStart(2, "0")}
      </Box>
      {mobileTitle && (
        <Box
          className="text-4xl md:text-5xl uppercase z-10 font-bold ml-20 -mt-0 lg:hidden"
          sx={{ whiteSpace: "pre-line" }}
        >
          {mobileTitle}
        </Box>
      )}

      <Box
        className={`text-4xl md:text-5xl uppercase z-10 font-bold ml-20 -mt-0 ${
          mobileTitle ? "lg:block hidden" : ""
        }`}
      >
        {title}
      </Box>
    </Box>
  );
};

export default InfoTitle;
