import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Link,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState } from "react";

const CookieDetails = ({ elements, cookieOptions, onChange }) => {
  return (
    <Box className="pb-4">
      {elements.map((element, index) => (
        <CookieGroup
          key={index}
          group={element}
          onChange={onChange}
          checked={cookieOptions[element.key]}
          disabled={Boolean(element.key === "necessary")}
        />
      ))}
    </Box>
  );
};

const CookieGroup = ({ group, checked, disabled = false, onChange }) => {
  const [expaned, setExpaned] = useState(false);

  function handleClick() {
    setExpaned(!expaned);
  }

  function handleChange(value) {
    onChange(group.key, value.target.checked);
  }

  return (
    <Accordion expanded={expaned}>
      <AccordionSummary expandIcon={<ExpandMoreIcon onClick={handleClick} />}>
        <Box>
          <Typography className="flex flex-row justify-between">
            <b onClick={handleClick}>{group.title}</b>
            <Switch
              disabled={disabled}
              checked={checked}
              onChange={handleChange}
            />
          </Typography>
          <Typography onClick={handleClick}>{group.text}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {group.cookies.map((cookie, index) => (
          <Cookies key={index} cookies={cookie} />
        ))}
        {group.cookies.length === 0 && (
          <Typography>
            <b>Es werden keine Cookies dieser Art verwendet</b>
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const Cookies = ({ cookies }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="flex flex-col gap-2">
          <Typography>
            <b>{cookies.title}</b>
          </Typography>
          {cookies.href && (
            <Link href={cookies.href}>
              <Button className="flex gap-2 justify-start items-center">
                Erfahren Sie mehr über diesen Anbieter
                <OpenInNewIcon />
              </Button>
            </Link>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {cookies.cookieList.map((cookie, index) => (
          <Cookie key={index} cookie={cookie} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const Cookie = ({ cookie }) => {
  return (
    <Paper className="p-2 flex flex-col gap-2">
      <Typography>
        <b>{cookie.cookieName}</b>
      </Typography>
      <Typography>{cookie.description}</Typography>
      <Divider />
      <Box className="flex flex-row justify-between">
        <Typography>Ablauf: {cookie.runtime}</Typography>
        <Typography>Typ: {cookie.type}</Typography>
      </Box>
    </Paper>
  );
};

export default CookieDetails;
