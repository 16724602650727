import { Box, Link, Typography } from "@mui/material";
import contact from "./contact";

const ImpressumContent = () => (
  <Box className="flex flex-col gap-2 py-3 px-4 md:px-0">
    <Typography variant="h2">Impressum</Typography>
    <Typography variant="h3">Verantwortlich</Typography>
    <Typography>Lancelot Pfleger, CEO von Pfleger Bau Service GmbH</Typography>
    <Typography>Angaben gemäß § 5 TMG:</Typography>
    <Typography>Pfleger Bau Service GmbH</Typography>
    <Typography>
      Eingetragen beim Amtsgericht Kaiserslautern im Handelsregister B 33164
    </Typography>
    <Typography variant="h3">Anschrift:</Typography>
    <Typography>Pfleger Bau Service GmbH</Typography>
    <Typography>Europaallee 33,</Typography>
    <Typography>D-67657 Kaiserslautern</Typography>
    <Typography>Steuernummer DE19/656/42338</Typography>
    <Typography>Umsatzsteuer-Identifikationsnummer DE342855515</Typography>

    {contact.map((con, index) => (
      <Link
        href={con.href}
        className="flex flex-row justify-start items-center gap-2"
      >
        {con.icon} {con.label}
      </Link>
    ))}
  </Box>
);

export default ImpressumContent;
