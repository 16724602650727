import { Box } from "@mui/system";
import GaleryItem from "./GaleryItem";

const InfoGalery = ({
  pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-01.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-01.jpg",
    },
  ],
}) => {
  const classes = [
    " xl:mt-10 w-2/3 h-auto xl:w-3/5 xl:h-1/3 aspect-square object-cover float-left",
    "-mt-10 xl:-mt-10 w-2/3 h-auto xl:w-3/5 xl:h-1/3 aspect-square object-cover float-right",
  ];

  return (
    <Box className="block w-full xl:w-1/2 relative">
      {pictures.map((picture, index) => (
        <GaleryItem key={index} picture={picture} className={classes[index]} />
      ))}
    </Box>
  );
};

export default InfoGalery;
