import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import TitlePic from "../../components/TitlePic";
import Datenschutz from "../../global/Datenschutz";

const Privacy = () => {
  const privacyRef = useRef(null);
  useEffect(() => {
    if (privacyRef) privacyRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <Box
      ref={privacyRef}
      sx={{
        "& ul": {
          listStyleType: "disc",
          marginBlockStart: "1em",
          marginBlockEnd: "1em",
          paddingInlineStart: "40px",
        },
      }}
    >
      <TitlePic
        title="Datenschutz"
        upperText="Ihre Daten sind sicher"
        src={{
          webp: "/media/title/PFLEGER BAUSERVICE TITLE-6.webp",
          jpg: "/media/title/PFLEGER BAUSERVICE TITLE-6.jpg",
        }}
      />
      <Datenschutz />
    </Box>
  );
};

export default Privacy;
