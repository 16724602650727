const times = [
  {
    day: "Montag",
    open: true,
    text: "08:00 - 16:00",
  },
  {
    day: "Dienstag",
    open: true,
    text: "08:00 - 16:00",
  },
  {
    day: "Mittwoch",
    open: true,
    text: "08:00 - 16:00",
  },
  {
    day: "Donnerstag",
    open: true,
    text: "08:00 - 16:00",
  },
  {
    day: "Freitag",
    open: true,
    text: "08:00 - 16:00",
  },
  {
    day: "Samstag",
    open: true,
    text: "nach Vereinbarung",
  },
  {
    day: "Sonntag",
    open: false,
    text: "geschlossen",
  },
];

export default times;
