import { Box } from "@mui/material";
import InfoSection from "../../../components/InfoSection";
import InfoGalery from "../../../components/InfoGalery";

const Tiefbau = () => {
  const bullets = [
    "Veränderung baulicher Anlagen",
    "Kellerabdichtung",
    "Allgemeine Baggerarbeiten",
  ];
  const pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-17.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-17.jpg",
    },
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-16.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-16.jpg",
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={7}
        title="Tiefbau"
        subTitle="Tief verwurzelt, hoch gebaut."
        bulletArray={bullets}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};

export default Tiefbau;
