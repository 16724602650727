import { Box, useTheme } from "@mui/material";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { tokens } from "../../theme/theme";
import ImageWithFallback from "../../components/ImageWithFallback";
const AutoplaySlider = withAutoplay(AwesomeSlider);
const ProjectCard = ({ images = [], title, service = "" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="flex flex-col h-full w-full relative">
      <Box
        className="aspect-square flex w-full"
        sx={{
          "& span": {
            height: "10px",
          },
          "& span::before, span::after": {
            backgroundColor: "white",
          },
          "& .awssld__controls__arrow-right": {
            marginRight: "20px",
          },
          "& .awssld__controls__arrow-left": {
            marginLeft: "20px",
          },
          "& nav": {
            position: "absolute",
            bottom: "65px",
            left: "0",
            width: "100%",
            zIndex: "10",
            padding: "1px",
            "& button": {
              width: "5px",
              height: "5px",
              backgroundColor: colors.glass,
            },
            "& .awssld__bullets--active": {
              backgroundColor: colors.contrast,
            },
          },
        }}
      >
        <AutoplaySlider
          className="h-full w-full"
          animation="cubeAnimation"
          play={false}
          cancelOnInteraction={false}
          interval={6000}
          bullets={true}
          mobileTouch={true}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              className="flex justify-start items-center w-full h-full"
            >
              <ImageWithFallback
                webp={image.webp}
                jpg={image.jpg}
                alt={title}
                className=" absolute top-0 left-0 h-full w-full object-cover"
              />
            </Box>
          ))}
        </AutoplaySlider>
      </Box>

      <Box
        className="absolute w-full bottom-0 left-0 z-10 px-2 py-2 text-center text-xl md:text-lg font-normal uppercase"
        sx={{
          backgroundColor: "rgba(0,0,0,0.7)",
          color: colors.contrast,
        }}
      >
        {title}
        <Box className="text-xs normal-case">{service}</Box>
      </Box>
    </Box>
  );
};

export default ProjectCard;
