import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { tokens } from "../theme/theme";
const TextSection = ({ title, text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="p-8">
      <Box
        className="py-5 px-4 flex flex-col gap-2"
        sx={{
          borderLeft: `1px solid ${colors.primary[500]}`,
          maxWidth: "900px",
        }}
      >
        <Typography variant="h2" className="uppercase">
          {title}
        </Typography>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
};

export default TextSection;
