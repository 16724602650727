import { Box, Typography } from "@mui/material";

const CookieText = ({ elements }) => {
  return (
    <Box className="flex flex-col gap-2">
      {elements.map((element, index) => (
        <Typography key={index} variant={element.variant}>
          {element.text}
        </Typography>
      ))}
    </Box>
  );
};

export default CookieText;
