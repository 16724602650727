const projectPath = "/media/projects/";

const projects = [
  {
    title: "Antoniushaus",
    service: "Entkernung",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-01.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-01.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-02.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-02.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-03.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-03.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-04.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-04.jpg`,
      },
    ],
  },
  {
    title: "Wohnhaussanierung",
    service: "Schadstoffsanierung",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-05.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-05.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-06.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-06.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-07.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-07.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-08.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-08.jpg`,
      },
    ],
  },
  {
    title: "Werkshallensanierung",
    service: "Schadstoffsanierung & Fachbauleitung",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-09.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-09.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-10.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-10.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-11.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-11.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-12.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-12.jpg`,
      },
    ],
  },
  {
    title: "Scheune & Wohnhaus",
    service: "Abbruch & Recycling",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-13.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-13.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-14.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-14.jpg`,
      },
    ],
  },
  {
    title: "Probenentnahme ",
    service: "Gutachten (Laga PN 98)",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-15.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-15.jpg`,
      },
    ],
  },
  {
    title: "Demontage Balkon",
    service: "Abbruch",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-16.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-16.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-17.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-17.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-18.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-18.jpg`,
      },
    ],
  },
  {
    title: "Bauvorbereitung",
    service: "Erdarbeiten",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-19.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-19.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-20.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-20.jpg`,
      },
    ],
  },
  {
    title: "Fassade Hochhaus",
    service: "Schadstoffsanierung",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-21.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-21.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-22.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-22.jpg`,
      },
    ],
  },
  {
    title: "Demontage Sonnenschutz",
    service: "Fachbauleitung",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-23.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-23.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-24.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-24.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-25.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-25.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-26.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-26.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-27.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-27.jpg`,
      },
    ],
  },
  {
    title: "Kernsanierung",
    service: "Entkernung",
    images: [
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-28.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-28.jpg`,
      },
      {
        webp: `${projectPath}PFLEGER BAUSERVICE PROJEKT-29.webp`,
        jpg: `${projectPath}PFLEGER BAUSERVICE PROJEKT-29.jpg`,
      },
    ],
  },
];

export default projects;
