import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import { useEffect, useRef } from "react";
import TitlePic from "../../components/TitlePic";
import Person from "../../components/Person";
import contact from "../../global/contact";
import { tokens } from "../../theme/theme";
import person from "../../global/person";
import Certificats from "./Certificats";

const AboutUs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const aboutUsRef = useRef(null);
  useEffect(() => {
    if (aboutUsRef) aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <Box ref={aboutUsRef}>
      <TitlePic
        title="Unsere Leidenschaft"
        upperText="Über uns"
        noText
        src={{
          webp: "/media/title/PFLEGER BAUSERVICE TITLE-9.webp",
          jpg: "/media/title/PFLEGER BAUSERVICE TITLE-9.jpg",
        }}
      />

      <Box className="mt-8 p-4 md:pl-0 grid grid-cols-1 2xl:grid-cols-2 gap-10 2xl:gap-0">
        <Person
          name={person.name}
          position={person.position}
          qualifications={person.qualifications}
          contact={contact}
        />
      </Box>
      <Certificats />
    </Box>
  );
};

export default AboutUs;
