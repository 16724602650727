import { Box, Link, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme/theme";
import socials from "../../global/socials";
import MadeBy from "./MadeBy";

const Socials = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="relative py-5 mx-8 flex flex-col gap-2"
      sx={{
        "::before, ::after": {
          content: '" "',
          position: "absolute",
          left: "0",
          height: "4px",
          width: "90px",
          backgroundColor: colors.highlight[500],
        },
        "::before": {
          top: "0",
        },
        "::after": {
          bottom: "0",
        },
      }}
    >
      <Box className="flex flex-row gap-2">
        {socials.map((item, index) => (
          <Link key={index} href={item.href} target="_blank">
            {item.icon}
          </Link>
        ))}
      </Box>
      <MadeBy />
    </Box>
  );
};

export default Socials;
