import { Box, Typography } from "@mui/material";

const Datenschutz = () => (
  <Box className="flex flex-col gap-2 py-3 px-4 md:px-0">
    <Typography variant="h2">Datenschutzerklärung</Typography>

    <Typography>
      Wir freuen uns über Ihren Besuch auf unserer Webseite
      https://pfleger-bau.de und Ihr Interesse an unserem Unternehmen und
      unseren Angeboten. Für externe Links zu fremden Inhalten übernehmen wir
      trotz sorgfältiger inhaltlicher Kontrolle keine Haftung, da wir die
      Übermittlung dieser Information nicht veranlasst, den Adressaten der
      übermittelten Information und die übermittelten Informationen selbst nicht
      ausgewählt oder verändert haben.
    </Typography>
    <Typography>
      Der Schutz Ihrer personenbezogenen Daten bei der Erhebung, Verarbeitung
      und Nutzung anlässlich Ihres Besuchs auf unseren Internetseiten ist uns
      ein wichtiges Anliegen und erfolgt im Rahmen der gesetzlichen
      Vorschriften, über die Sie sich z.B. unter www.bfd.bund.de informieren
      können.
    </Typography>
    <Typography>
      Im Folgenden erläutern wir Ihnen, welche Informationen wir während Ihres
      Besuchs auf unseren Webseiten erfassen und wie diese genutzt werden:
    </Typography>

    <Typography variant="h3">
      1. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck
      von deren Verwendung
    </Typography>

    <Typography>a) Beim Besuch der Webseite</Typography>
    <Typography>
      Bei jedem Zugriff eines Kunden (oder sonstigen Besuchers) auf unserer
      Webseite werden durch den auf Ihrem Endgerät (Computer, Laptop, Tablet,
      Smartphone, etc.) zum Einsatz kommenden Internet-Browser automatisch
      Informationen an den Server unserer Webseite gesendet. Diese Informationen
      werden temporär in einem sog. Logfile (Protokolldatei) gespeichert.
      <br />
    </Typography>
    <Typography>
      Folgende Daten werden dabei ohne Ihr Zutun erfasst und bis zur
      automatisierten Löschung gespeichert:/p&gt;
    </Typography>
    <ul>
      <li>
        IP-Adresse des anfragenden Rechners, sowie Geräte-ID oder individuelle
        Geräte-Kennung und Gerätetyp,
      </li>
      <li>
        Name der abgerufenen Datei und übertragene Datenmenge, sowie Datum und
        Uhrzeit des Abrufs,
      </li>
      <li>Meldung über erfolgreichen Abruf,</li>
      <li>anfragende Domain,</li>
      <li>
        Beschreibung des Typs des verwendeten Internet-Browsers und ggf. des
        Betriebssystems Ihres Endgeräts sowie der Name Ihres Access-Providers,
      </li>
      <li>
        Ihre Browser-Verlaufsdaten sowie Ihre standardmäßigen
        Weblog-Informationen,
      </li>
    </ul>
    <Typography>
      Unser berechtigtes Interesse gem. Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1
      lit.&nbsp;f DSGVO zur Erhebung der Daten beruht auf den folgenden Zwecken:
      Gewährleistung eines reibungslosen Verbindungsaufbaus und einer
      komfortablen Nutzung der Webseite, Auswertung der Systemsicherheit und
      -stabilität sowie zu weiteren administrativen Zwecken.
    </Typography>

    <Typography>
      In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck,
      Rückschlüsse auf Ihre Person zu ziehen.
    </Typography>
    <Typography>b) Bei Nutzung unseres Kontaktformulars</Typography>
    <Typography>
      Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über
      ein auf der Webseite bereitgestelltes Formular Kontakt aufzunehmen. Dabei
      sind mindestens die folgenden Angaben erforderlich: , damit wir wissen,
      von wem die Anfrage stammt und um diese beantworten zu können. Weitere
      Angaben können freiwillig getätigt werden.
      <br />
      Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt gem.
      Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO auf Grundlage Ihrer
      freiwillig erteilten Einwilligung.
      <br />
      Die für die Benutzung des Kontaktformulars von uns erhobenen
      personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten
      Anfrage automatisch gelöscht.
      <br />
    </Typography>

    <Typography>c) Bei Abschluss eines Vertragsverhältnisses</Typography>
    <Typography>
      Bei Abschluss eines Vertragsverhältnisses auf unserer Webseite bitten wir
      Sie um die Angaben folgender personenbezogener Daten:
    </Typography>

    <Typography>
      - weitere personenbezogene Daten, zu deren Erfassung und Verarbeitung wir
      gesetzlich verpflichtet oder berechtigt sind und die wir für Ihre
      Authentifizierung, Identifizierung oder zur Überprüfung der von uns
      erhobenen Daten benötigen.
    </Typography>
    <Typography>
      Die genannten Daten werden zur Abwicklung des Vertragsverhältnisses
      verarbeitet. Die Verarbeitung der Daten erfolgt aufgrund von Art. 6 Abs. 1
      lit. b DSGVO. Die Speicherfrist ist auf den Vertragszweck und, sofern
      vorhanden, gesetzliche und vertragliche Aufbewahrungspflichten beschränkt.
    </Typography>

    <Typography variant="h3">
      2. Weitergabe von personenbezogenen Daten
    </Typography>
    <Typography>
      Eine Übermittlung Ihrer Daten an Dritte zu anderen als den im Folgenden
      aufgeführten Zwecken findet nicht statt.
    </Typography>

    <Typography>Wir geben Ihre Daten nur an Dritte weiter, wenn:</Typography>
    <ul>
      <li>
        Sie eine ausdrückliche Einwilligung dazu erteilt haben nach (Art. 6 Abs.
        1 S. 1 lit. a DSGVO),
      </li>
      <li>
        dies für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich
        ist (Art. 6 Abs. 1 lit. b DSGVO),
      </li>
      <li>
        eine gesetzliche Verpflichtung zur Weitergabe besteht (Art.6 Abs.1 lit.
        c DSGVO),
      </li>
      <li>
        die Weitergabe zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht,
        dass Sie ein überwiegendes schutzwürdiges Interesse an der
        Nichtweitergabe Ihrer Daten haben (Art. 6 Abs. 1 S. 1 lit. f DSGVO).
      </li>
    </ul>

    <Typography>
      In diesen Fällen beschränkt sich der Umfang der übermittelten Daten jedoch
      nur auf das erforderliche Minimum.
    </Typography>

    <Typography>
      Unsere Datenschutzbestimmungen stehen im Einklang mit den geltenden
      datenschutzrechtlichen Bestimmungen und die Daten werden nur in der
      Bundesrepublik Deutschland / Europäischen Union verarbeitet. Eine
      Übermittlung in Drittländer findet nicht statt und ist nicht beabsichtigt.
    </Typography>

    <Typography variant="h3">3. Betroffenenrechte</Typography>
    <Typography>
      Auf Anfrage werden wir Sie gern informieren, ob und welche
      personenbezogenen Daten zu Ihrer Person gespeichert sind (Art.&nbsp;15
      DSGVO), insbesondere über die Verarbeitungszwecke, die Kategorie der
      personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen
      Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das
      Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
      Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die
      Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
      über das Bestehen einer automatisierten Entscheidungsfindung
      einschließlich Profiling.
    </Typography>
    <Typography>
      Ihnen steht zudem das Recht zu, etwaig unrichtig erhobene personenbezogene
      Daten berichtigen oder unvollständig erhobene Daten vervollständigen zu
      lassen (Art.&nbsp;16 DSGVO).
    </Typography>
    <Typography>
      Ferner haben Sie das Recht, von uns die Einschränkung der Verarbeitung
      Ihrer Daten zu verlangen, sofern die gesetzlichen Voraussetzungen hierfür
      vorliegen (Art.&nbsp;18 DSGVO).
    </Typography>
    <Typography>
      Sie haben das Recht, die Sie betreffenden personenbezogenen Daten in einem
      strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die
      Übermittlung an einen anderen Verantwortlichen zu verlangen (Art.&nbsp;20
      DSGVO).
    </Typography>
    <Typography>
      Darüber hinaus steht Ihnen das sogenannte „Recht auf Vergessenwerden“ zu,
      d.h. Sie können von uns die Löschung Ihrer personenbezogenen Daten
      verlangen, sofern hierfür die gesetzlichen Voraussetzungen vorliegen
      (Art.&nbsp;17 DSGVO).
    </Typography>
    <Typography>
      Unabhängig davon werden Ihre personenbezogenen Daten automatisch von uns
      gelöscht, wenn der Zweck der Datenerhebung weggefallen oder die
      Datenverarbeitung unrechtmäßig erfolgt ist.
    </Typography>
    <Typography>
      Gemäß Art.&nbsp;7 Abs.&nbsp;3 DSGVO haben Sie das Recht Ihre einmal
      erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur
      Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung
      beruhte, für die Zukunft nicht mehr fortführen dürfen.
    </Typography>
    <Typography>
      Sie haben zudem das Recht, jederzeit gegen die Verarbeitung Ihrer
      personenbezogenen Daten Widerspruch zu erheben, sofern ein
      Widerspruchsrecht gesetzlich vorgesehen ist. Im Falle eines wirksamen
      Widerrufs werden Ihre personenbezogenen Daten ebenfalls automatisch durch
      uns gelöscht (Art.&nbsp;21 DSGVO).
    </Typography>
    <Typography>
      Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen,
      genügt eine E-Mail an: info@pfleger-bau.de.
    </Typography>
    <Typography>
      Bei Verstößen gegen die datenschutzrechtlichen Vorschriften haben Sie gem.
      Art.&nbsp;77 DSGVO die Möglichkeit, Beschwerde bei einer Aufsichtsbehörde
      zu erheben.
    </Typography>
    <Typography variant="h3">4. Dauer der Datenspeicherung</Typography>
    <Typography>
      Die erhobenen Daten werden solange bei uns gespeichert, wie dies für die
      Durchführung der mit uns eingegangen Verträge erforderlich ist oder Sie
      Ihr Recht auf Löschung oder Ihr Recht auf Datenübertragung auf ein anderes
      Unternehmen nicht ausgeübt haben.
    </Typography>

    <Typography variant="h3">5. Cookies</Typography>
    <Typography>
      Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es sich um
      kleine Text-Dateien, die Ihr Browser automatisch erstellt und die auf
      Ihrem Endgerät gespeichert werden, wenn Sie unsere Webseite besuchen. In
      dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang
      mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch
      nicht, dass wir unmittelbar Kenntnis von Ihrer Identität erhalten. Diese
      Cookies sind werden als sog. First-Party-Cookies (“eigene Cookies”) oder
      Third-Party-Cookies (“Drittanbietercookies”) gesetzt. First-Party-Cookies
      werden durch die Webseite gesetzt, auf der Sie sich gerade befinden und
      werden von Browsern nicht domainübergreifend zugänglich gemacht. Ein
      Third-Party-Cookie hingegen wird durch einen Dritten gesetzt, also nicht
      durch die eigentliche Webseite, auf der Sie sich gerade befinden. Die
      Cookies werden darüber hinaus in technisch notwendige und technisch nicht
      notwendige Cookies unterschieden. Auf unserer Webseite werden sowohl
      technisch notwendige Cookies als auch technisch nicht notwendige Cookies
      nach Maßgabe der folgenden Absätze gesetzt.
    </Typography>
    <Typography>
      Technisch notwendige Cookies sind für den Betrieb unserer Webseite
      zwingend erforderlich und führen z.B. dazu, dass Ihnen bestimmte
      Funktionen überhaupt erst ermöglicht werden. Diese technisch notwendigen
      Cookies, die nur für die einzelne notwendige Online-Sitzung benötigt und
      gesetzt werden, werden nach Verlassen unserer Webseite automatisch
      gelöscht.
      <br />
    </Typography>
    <Typography>
      Bei der Bereitstellung unseres Internetangebots werden Java-Applets und
      Java-Script verwendet. Falls Sie sich aus Sicherheitsgründen diese
      Hilfsprogramme bzw. aktiven Inhalte nicht nutzbar machen wollen, sollten
      Sie die entsprechende Einstellung Ihres Browsers deaktivieren.
    </Typography>
    <Typography variant="h3">6. Online-Marketing/ Analyse-Tools</Typography>
    <Typography>keine</Typography>

    <Typography>WhatsApp</Typography>
    <Typography>
      Auf unserer Webseite sind Plugins des Instant-Messaging-Dienst WhatsApp,
      welches von Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin
      2, Irland betrieben wird, integriert. Beim Anklicken des Plugins (grüne
      Sprechblase mit einem Telefonhörer) wird eine Verbindung zu den Servern
      von WhatsApp erstellt. Sofern Sie bei WhatsApp angemeldet sind, können Sie
      unsere Seite teilen. Hierbei werden Protokolldaten an den Server von
      WhatsApp geschickt. Diese können möglicherweise Ihre IP-Adresse, die
      Adresse der besuchten Webseiten, die ebenfalls WhatsApp-Funktionen
      enthalten, Art und Einstellungen des Browsers, Datum und Zeitpunkt der
      Anfrage, Ihre Verwendungsweise von WhatsApp sowie Cookies enthalten. Auch
      wenn Sie nicht bei WhatsApp angemeldet sind, können wir nicht garantieren,
      dass Ihre IP-Adresse von WhatsApp gespeichert wird. Weitere Informationen
      erhalten Sie der Datenschutzrichtlinie von WhatsApp:
      https://www.whatsapp.com/legal/#privacy-policyUnsere{" "}
    </Typography>

    <Typography>Facebook</Typography>
    <Typography>
      Auf unserer Webseite werden Social Plugins (“Plugins”) des sozialen
      Netzwerkes facebook.com verwendet, welches von Meta Platforms Ireland
      Limited, 4 Grand Canal Square, Dublin 2, Irland betrieben wird
      (“Facebook”). Wenn Sie unsere Webseite mit dem Plugin öffnen, stellt diese
      über Ihren Browser eine direkte Verbindung mit den Servern von Facebook
      her. Hierdurch wird an Facebook die Information übertragen, dass Sie die
      Seite aufgerufen haben.
    </Typography>

    <Typography>
      Sollten Sie mit Ihrem Facebook-Account eingeloggt sein, kann durch das
      Anklicken des Plugins der Besuch unserer Webseite direkt ihrem Profil
      zugeordnet werden. Auch wenn sie kein Profil besitzen, kann nicht
      ausgeschlossen werden, dass Ihre IP Adresse von Facebook gespeichert wird.
    </Typography>

    <Typography>
      Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
      Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und
      Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie
      bitte den Datenschutzhinweisen (https://www.facebook.com/about/privacy/)
      von Facebook.
    </Typography>

    <Typography>
      Wenn Sie Mitglied bei Facebook sind und nicht möchten, dass Facebook über
      unser Onlineangebot Daten über Sie sammelt und mit Ihren, bei Facebook
      gespeicherten Mitgliedsdaten verknüpft, müssen Sie sich vor dem Besuch
      unser Webseite bei Facebook ausloggen und entsprechende Facebook-Cookies
      löschen. Ebenfalls ist es möglich Facebook-Social-Plugins mit Add-ons für
      den Browser zu blocken, zum Beispiel mit dem “Facebook Blocker”.
    </Typography>

    <Typography>Twitter</Typography>
    <Typography>
      Auf unserer Webseite sind zudem Funktionen des Dienstes Twitter
      eingebunden. Diese Funktionen werden angeboten durch die Twitter Inc.,
      Twitter, Inc. 1355 Market St, Suite 900, San Francisco, CA 94103, USA.
      Durch das Benutzen von Twitter und der Funktion “Re-Tweet” werden die von
      Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und anderen
      Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter übertragen.
      Wir weisen darauf hin, dass wir als Anbieter unserer Webseite keine
      Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
      Twitter erhalten. Weitere Informationen hierzu finden Sie in der
      Datenschutzerklärung von Twitter unter http://twitter.com/privacy. Ihre
      Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen
      unter http://twitter.com/account/settings ändern. Um zu verhindern, dass
      Twitter die Daten beim Besuch unserer Internetseite sammelt, loggen Sie
      sich vor dem Besuch bei Twitter aus. Um einen generellen Zugriff von
      Twitter auf Ihre Daten über Internetseiten zu verhindern, können Sie
      Twitter Social-Plugins durch ein Add-On für Ihren Browser (z. B.
      "Twitter-Blocker", https://disconnect.me) ausschließen.
    </Typography>

    <Typography>Instagram</Typography>
    <Typography>
      Auf unserer Webseite sind Plugins des sozialen Netzwerks Instagram (Meta
      Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland)
      integriert. Das Instagram Plugin erkennen Sie an dem “Instagram-Button”
      auf unserer Webseite. Wenn Sie den Instagram-Button anklicken während Sie
      im Instagram-Account eingeloggt sind, können Sie die Inhalte unserer
      Webseiten auf Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
      Besuch unserer Seiten Ihrem Benutzerkonto zuordnen.
    </Typography>
    <Typography>
      Wir weisen darauf hin, dass wir als Anbieter unserer Webseite keine
      Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
      Instagram erhalten. Weitere Informationen zur Datenschutzerklärung von
      Instagram erhalten Sie unter http://instagram.com/about/legal/privacy/.
    </Typography>

    <Typography>LinkedIn</Typography>
    <Typography>
      Auf unserer Webseite sind Plugins des sozialen Netzwerks LinkedIn
      integriert. Anbieter ist die LinkedIn Corporation, 2029 Stierlin Court,
      Mountain View, CA 94043, USA. Beim Anklicken des LinkedIn-Buttons wird
      eine Verbindung zu den Servern von LinkedIn hergestellt. Der Inhalt des
      Plugins wird von LinkedIn direkt an Ihren Browser übermittelt und von
      diesem in die Webseite eingebunden. Hierdurch wird die Information, dass
      Sie unsere Webseite besucht haben, an LinkedIn weitergeleitet.
    </Typography>
    <Typography>
      Sollten Sie mit Ihrem LinkedIn-Account eingeloggt sein, kann durch das
      Anklicken des LinkedIn-Buttons der Besuch unserer Webseite direkt mit
      ihrem Profil zugeordnet werden. Auch wenn sie kein Profil besitzen, kann
      nicht ausgeschlossen werden, dass Ihre IP-Adresse von LinkedIn gespeichert
      wird.
    </Typography>
    <Typography>
      Wir weisen darauf hin, dass wir als Anbieter der Webseite keine Kenntnis
      vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn
      haben. Weitere Informationen zur Datenschutzerklärung von LinkedIn
      erhalten Sie unter https://www.linkedin.com/legal/privacy-policy.
    </Typography>

    <Typography variant="h3">Datensicherheit</Typography>
    <Typography>
      Wir sind um alle notwendigen technischen und organisatorischen
      Sicherheitsmaßnahmen bemüht, um Ihre personenbezogenen Daten so zu
      speichern, dass sie weder Dritten noch der Öffentlichkeit zugänglich sind.
      Sollten Sie mit uns per E-Mail in Kontakt treten wollen, so weisen wir Sie
      darauf hin, dass bei diesem Kommunikationsweg die Vertraulichkeit der
      übermittelten Informationen nicht vollständig gewährleistet werden kann.
      Wir empfehlen Ihnen daher, uns vertrauliche Informationen ausschließlich
      über den Postweg zukommen zu lassen.
    </Typography>
    <Typography variant="h3">
      Aktualität und Änderung dieser Datenschutzerklärung
    </Typography>
    <Typography>Diese Datenschutzerklärung ist aktuell gültig. </Typography>
    <Typography>
      Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder
      aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben
      kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die
      jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Webseite
      unter https://pfleger-bau.de/datenschutz von Ihnen abgerufen und
      ausgedruckt werden.
    </Typography>

    <Typography>
      Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
    </Typography>
    <Typography>
      Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
    </Typography>
    <Typography>
      Lancelot Pfleger / Europaallee 33, 67657 Kaiserslautern / +49 151 59 1818
      59 / info@pfleger-bau.de
    </Typography>
  </Box>
);

export default Datenschutz;
