import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";

const contact = [
  {
    label: "info@pfleger-bau.de",
    href: "mailto:info@pfleger-bau.de",
    icon: <EmailTwoToneIcon />,
    type: "iconLink",
  },
  {
    label: "+49151 59 1818 59",
    href: "tel:+4915159181859",
    icon: <LocalPhoneTwoToneIcon />,
    type: "iconLink",
  },
];

export default contact;
