import { Box, Link, Typography } from "@mui/material";
import madeby from "../../global/madeby";

const MadeBy = () => {
  return (
    <Box className="flex justify-start items-center gap-3">
      <Typography variant="h6">Made and designed by </Typography>
      <Link href={madeby.href} target="_blank">
        <img src={madeby.src} className="w-12" />
      </Link>
    </Box>
  );
};

export default MadeBy;
