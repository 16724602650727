import { Box } from "@mui/material";
import InfoSection from "../../../components/InfoSection";
import InfoGalery from "../../../components/InfoGalery";

const Schadstoffsanierung = () => {
  const bullets = [
    "Asbest nach TRGS 519",
    "KMF nach TRGS 521",
    "Kontaminierter Bereich nach TRGS 524",
    "PAK / PCP / PCB",
    "Schimmelpilz",
    "Brandsanierung",
    "Materialbeprobung nach LAGA PN98",
  ];
  const pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-02.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-02.jpg",
    },
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-12.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-12.jpg",
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={4}
        title="Schadstoffsanierung"
        mobileTitle={`Schadstoff-\nsanierung`}
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};

export default Schadstoffsanierung;
