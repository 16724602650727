import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const socials = [
  {
    label: "Facebook",
    icon: <FacebookIcon />,
    href: "https://www.facebook.com/profile.php?id=100067374826965",
    type: "iconLink",
  },
];
/*
const socials = [
  {
    label: "Facebook",
    icon: <FacebookIcon />,
    href: "https://www.facebook.com/profile.php?id=100067374826965",
    type: "iconLink",
  },
  {
    label: "Instagram",
    icon: <InstagramIcon />,
    href: "https://www.facebook.com/profile.php?id=100067374826965",
    type: "iconLink",
  },
  {
    label: "WhatsApp",
    icon: <WhatsAppIcon />,
    href: "https://www.facebook.com/profile.php?id=100067374826965",
    type: "iconLink",
  },
];
*/
export default socials;
