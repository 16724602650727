import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Box } from "@mui/material";
import Sidebar from "./navigation/Sidebar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Topbar from "./navigation/Topbar";
import { BrowserRouter } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/theme";
import { useState } from "react";
import Projects from "./pages/projects";
import Contact from "./pages/contact";
import Impressum from "./pages/impressum";
import Privacy from "./pages/privacy";
import MobileMenu from "./navigation/MobileMenu";
import Div100vh from "react-div-100vh";
import Footer from "./navigation/Footer";
import AboutUs from "./pages/about-us";
import CookieConsentBanner from "./components/cookies/CookieConsentBanner";

function App() {
  const [theme, colorMode] = useMode();

  const [currentLocation, setCurrentLocation] = useState("/");

  function handleLocationChange(newLocation) {
    setCurrentLocation(newLocation);
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Div100vh>
            <CookieConsentBanner />
            <Box className=" w-full h-full flex flex-row overflow-hidden">
              <Sidebar
                location={currentLocation}
                onLocationChange={handleLocationChange}
              />
              <Box className="h-full w-full overflow-hidden flex flex-col ">
                <Topbar />
                <MobileMenu
                  location={currentLocation}
                  onLocationChange={handleLocationChange}
                />

                <Box
                  id="main"
                  className="overflow-y-auto overflow-x-hidden h-full xl:snap-y xl:snap-mandatory"
                >
                  <Routes>
                    <Route
                      path="*"
                      element={<Home onLocationChange={handleLocationChange} />}
                    />
                    <Route path="/projekte" element={<Projects />} />
                    <Route path="/kontakt" element={<Contact />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz" element={<Privacy />} />
                    <Route path="/über-uns" element={<AboutUs />} />
                  </Routes>
                  <Footer onLocationChange={handleLocationChange} />
                </Box>
              </Box>
            </Box>
          </Div100vh>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
