import { Box, Link, Typography } from "@mui/material";
import TitlePic from "../../../components/TitlePic";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partners from "../../../global/partners";
import TextSection from "../../../components/TextSection";

const Partner = () => {
  return (
    <Box className="h-full w-full flex flex-col">
      <TitlePic
        title="Partner"
        upperText="Qualität & Sicherheit"
        src={{
          webp: "/media/title/PFLEGER BAUSERVICE TITLE-2.webp",
          jpg: "/media/title/PFLEGER BAUSERVICE TITLE-2.jpg",
        }}
      />

      <TextSection
        title="Vertrauen das weiter bringt"
        text="Unsere Mission im Baugewerbe ist es, die hohen Standards und die Qualität zu bieten, die unsere Kunden verdienen. Dabei spielen unsere wertvollen Partner eine entscheidende Rolle. Unser Team von Fachleuten verfügt über langjährige Erfahrung und Expertise im Bereich Abriss und Schadstoffsanierung. Ihre technische Kompetenz, ihre Präzision und ihr Engagement für Umweltverträglichkeit und Sicherheit sind die Grundpfeiler unseres Geschäfts."
      />
      <Box className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 w-full  gap-10 p-8 xl:p-4">
        {partners.map((partner, index) => (
          <PartnerCompany
            key={index}
            logoSrc={partner.logo}
            name={partner.name}
            href={partner.url}
            description={partner.description}
          />
        ))}
      </Box>
    </Box>
  );
};

const PartnerCompany = ({ logoSrc, name, description, href }) => {
  return (
    <Box className="text-lg text-center flex flex-col justify-start items-center">
      <img src={logoSrc} className=" aspect-square w-56 object-contain" />
      <Typography variant="h4" textAlign="center">
        {description}
      </Typography>
    </Box>
  );
};

export default Partner;
