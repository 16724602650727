import { Box, Typography } from "@mui/material";
import ProjectCard from "./ProjectCard";
import projects from "../../global/projects";
import TitlePic from "../../components/TitlePic";
import { useEffect, useRef } from "react";

const Projects = () => {
  const projectsRef = useRef(null);
  useEffect(() => {
    if (projectsRef) projectsRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Box ref={projectsRef}>
      <TitlePic
        title="Projekte"
        upperText="Ihre Vision, unsere Projekte."
        src={{
          webp: "/media/title/PFLEGER BAUSERVICE TITLE-5.webp",
          jpg: "/media/title/PFLEGER BAUSERVICE TITLE-5.jpg",
        }}
      />
      <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-5 px-5 md:px-0 md:pr-5 py-16">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            images={project.images}
            title={project.title}
            service={project.service}
          />
        ))}
      </Box>
    </Box>
  );
};
export default Projects;
