import { Box, Typography } from "@mui/material";

const person = {
  img: {
    webp: "/media/contact/PFLEGER BAUSERVICE PERSON.webp",
    jpg: "/media/contact/PFLEGER BAUSERVICE PERSON.jpg",
  },
  name: "Lancelot Pfleger",
  position: "Geschäftsführer",
  qualifications: [
    "zert. Bauleiter / IHK",
    "staatl. gepr. Bautechniker (Hochbau)",
    "gepr. Bausachverständiger (VfB)",
    "gepr. Wertgutachter (VfB)",
    "SiGeKo nach RAB 30",
  ],
  aboutUs: (
    <Box>
      <Typography textAlign="justify">
        Nach meiner Wehrdienstzeit im Jahre 2008 in der Klotzberg-Kaserne in
        Idar-Oberstein habe ich mich dazu entschieden, eine Lehre zum
        Dachdeckergesellen in München zu absolvieren. Diese absolvierte ich
        erfolgreich und wurde dementsprechend in der Region "Oberbayern" auf
        einem Podiumsplatz ausgezeichnet, worauf ich heute noch sehr Stolz bin.
        Nach ein paar Jahren in der Praxis und dem bis dato angesammelten
        Wissen, entschloss ich mich im Jahre 2015 für die Ausbildung zum
        staatlich geprüften Hochbautechniker an der MHK in Kaiserslautern.
      </Typography>
      <Typography textAlign="justify">
        Auch diese Ausbildung schloss ich mit Erfolg ab.
      </Typography>
      <Typography textAlign="justify">
        Darauf aufbauend sammelte ich im Anschluss Erfahrungen in der operativen
        Bauleitung in den Bereichen Erdbau, Rückbau, Hochbau, Schlüsselfertigbau
        sowie der Schadstoffsanierung. Im Zuge meiner aktiven Bauleitungszeit in
        Anstellung bei den zuvor genannten Fachbereichen, merkte ich, dass mir
        insbesondere das Themengebiet "Schadstoffe" und "Abbruch" Freude
        bereitet und dementsprechend eine fachkompetente Beratung sowie die
        Gesamtabwicklung der Projekte leicht fallen. Um meine, in diesem
        Zeitraum entstandene, Wissbegierde zu stillen, absolvierte ich
        berufsbegleitend diverse Fortbildungen um die fachbezogene Lizenzen wie
        z.B. die TRGS 519, 521, 524, Koordinator nach DGUV 101-004 Anlage 6a+6b,
        ehem. BGR128, SiGeKo nach RAB 30, Schimmelpilze und BiostoffVerordnung,
        nachweisen zu können, bevor ich im Jahre 2021 den Schritt in die
        Selbstständikeit wagte. Fortan war dies nicht mein Beruf sondern meine
        Berufung.
      </Typography>
      <Typography textAlign="justify">
        Zu guter Letzt durchlief ich, um meine Klienten exklusiv und
        fachkompetent zu beraten, die Weiterbildung zum geprüften
        Bausachverständiger für die Bewertung von Schäden an und in Gebäuden
        (VfB) sowie den geprüften Baugutachter Werteermittlung.
      </Typography>
      <Typography textAlign="justify">
        Meine Passion ist es, dass speziell das Themengebiet der Schadstoffe,
        welche in meinem Tätigkeitsbereich fast überall auftreten, den
        Fachfremden bzw. Laien, verständlich zu machen, diese hierfür zu
        sensibilisiern und entsprechend, auch aus rechtlicher Sicht, beratend
        zur Seite zu stehen.
      </Typography>
    </Box>
  ),
};
export default person;
