import ImageWithFallback from "./ImageWithFallback";

const GaleryItem = ({ picture, className }) => {
  return (
    <ImageWithFallback
      webp={picture.webp}
      jpg={picture.jpg}
      className={` object-cover ${className}`}
    />
  );
};

export default GaleryItem;
