import { Box } from "@mui/material";
import InfoSection from "../../../components/InfoSection";
import InfoGalery from "../../../components/InfoGalery";

const Entkernung = () => {
  const bullets = [
    "Entkernung aller Art",
    "Entrümpelung",
    "Entsorgung",
    "Kernsanierung",
    "Statischer Rückbau der Bausubstanz",
  ];
  const pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-05.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-05.jpg",
    },
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-10.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-10.jpg",
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={3}
        title="Entkernung"
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};

export default Entkernung;
