import { Button, Link, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { tokens } from "../theme/theme";
import ImageWithFallback from "./ImageWithFallback";
import PersonIcon from "@mui/icons-material/Person";

const Person = ({
  src,
  name = "Max Mustermann",
  position = "Geschäftsführer",
  qualifications = ["test0", "test1", "test2"],
  contact = [],
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="flex flex-col lg:flex-row gap-5">
      {src ? (
        <ImageWithFallback
          webp={src.webp}
          jpg={src.jpg}
          alt={name}
          className=" aspect-square object-cover w-3/4 sm:w-96  sm:h-96"
        />
      ) : (
        <Box
          className="flex justify-center items-center aspect-square object-cover w-3/4 sm:w-96  sm:h-96 "
          sx={{ backgroundColor: colors.card }}
        >
          <PersonIcon sx={{ fontSize: "300px" }} />
        </Box>
      )}

      <Box className="flex flex-col gap-0">
        <Box className="text-3xl">{name}</Box>
        <Box className="text-lg">{position}</Box>
        <Box>
          {qualifications.map((quali, index) => (
            <Box key={index} className="text-sm italic">
              {quali}
            </Box>
          ))}
        </Box>
        <Box className="flex flex-col gap-2 py-2">
          {contact.map((element, index) => (
            <Link href={element.href}>
              <Button className="flex gap-2 justify-center items-center">
                {element.icon}
                {element.label}
              </Button>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Person;
