import { Box } from "@mui/material";
import InfoSection from "../../../components/InfoSection";
import InfoGalery from "../../../components/InfoGalery";

const Bauleitung = () => {
  const bullets = [
    "Koordination",
    "Qualitätskontrolle",
    "Kostenkontrolle",
    "Kommunikation",
    "Baustellenmanagement",
    "Terminplanung",
    "Abbruchplan/Rückbaukonzept",
  ];
  const pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-08.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-08.jpg",
    },
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-13.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-13.jpg",
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={1}
        title="Fachbauleitung"
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};

export default Bauleitung;
