import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const InfoBullet = ({ text, icon = <CheckIcon fontSize="2rem" /> }) => {
  return (
    <Box className="flex flex-row gap-3 text-xl font-semibold uppercase">
      {icon}
      {text}
    </Box>
  );
};

export default InfoBullet;
