import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import EngineeringTwoToneIcon from "@mui/icons-material/EngineeringTwoTone";
import Bauleitung from "../pages/home/sektions/Bauleitung";
import HeroSektion from "../pages/home/sektions/HeroSektion";
import Abbruch from "../pages/home/sektions/Abbruch";
import Entkernung from "../pages/home/sektions/Entkernung";
import Schadstoffsanierung from "../pages/home/sektions/Schadstoffsanierung";
import Gutachten from "../pages/home/sektions/Gutachten";
import SägenBohren from "../pages/home/sektions/SägenBohren";
import Tiefbau from "../pages/home/sektions/Tiefbau";

const navItemList = [
  {
    name: "Leistungen",
    items: [
      {
        label: "Start",
        icon: <EngineeringTwoToneIcon />,
        route: "/",
        component: <HeroSektion />,
      },
      {
        label: "Fachbauleitung",
        icon: <EngineeringTwoToneIcon />,
        route: "/bauleitung",
        component: <Bauleitung />,
      },
      {
        label: "Abbruch",
        icon: <HomeTwoToneIcon />,
        route: "/abbruch",
        component: <Abbruch />,
      },
      {
        label: "Entkernung",
        icon: <HomeTwoToneIcon />,
        route: "/entkernung",
        component: <Entkernung />,
      },
      {
        label: "Schadstoffsanierung",
        icon: <HomeTwoToneIcon />,
        route: "/schadstoffsanierung",
        component: <Schadstoffsanierung />,
      },
      {
        label: "Bausachverständiger",
        icon: <HomeTwoToneIcon />,
        route: "/gutachten",
        component: <Gutachten />,
      },
      {
        label: "Betonsägen/Kernbohren",
        icon: <HomeTwoToneIcon />,
        route: "/bohren",
        component: <SägenBohren />,
      },
      {
        label: "Tiefbau",
        icon: <HomeTwoToneIcon />,
        route: "/tiefbau",
        component: <Tiefbau />,
      },
    ],
  },
  {
    name: "Sonstiges",
    items: [
      {
        label: "Projekte",
        icon: <HomeTwoToneIcon />,
        route: "/projekte",
      },
      {
        label: "Leistungspartner",
        icon: <HomeTwoToneIcon />,
        route: "/partner",
      },
      {
        label: "Über uns",
        icon: <HomeTwoToneIcon />,
        route: "/über-uns",
      },
      {
        label: "Kontakt",
        icon: <HomeTwoToneIcon />,
        route: "/kontakt",
      },
    ],
  },
  {
    name: "Rechtliches",
    items: [
      {
        label: "Impressum",
        icon: <HomeTwoToneIcon />,
        route: "/impressum",
      },
      {
        label: "Datenschutzerklärung",
        icon: <HomeTwoToneIcon />,
        route: "/datenschutz",
      },
    ],
  },
];

export default navItemList;
