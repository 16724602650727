import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";

const tabsDemo = [
  {
    label: "DEMO Tab1",
    content: <Box>TEST1</Box>,
  },
  {
    label: "DEMO Tab2",
    content: <Box>TEST2</Box>,
  },
];

const CookieTabs = ({
  tabs = tabsDemo,
  orientation = "horizontal",
  allowOverflow = false,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box
      className="w-full flex flex-col backdrop-blur-lg rounded-lg"
      sx={{
        height: allowOverflow ? "unset" : "100%",
        flexDirection: orientation === "horizontal" ? "column" : "row",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        orientation={orientation}
        sx={{
          "& button": {
            borderRadius: "0.5rem",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab label={tab.label} {...a11yProps(index)} />
        ))}
      </Tabs>
      <Box
        className="p-2 w-full flex relative  overflow-y-auto"
        sx={{ height: allowOverflow ? "unset" : "100%" }}
      >
        {tabs.map((tab, index) => (
          <ExoTabPanel active={value === index} tab={tab} />
        ))}
      </Box>
    </Box>
  );
};

export const ExoTabPanel = ({ active, tab }) => {
  return (
    <Box className={(!active && "hidden") + " h-full flex relative w-full"}>
      {tab.content}
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `exo-tabpanel-${index}`,
  };
}

export default CookieTabs;
