import { Box, Link, Paper, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";
import { useNavigate } from "react-router-dom";
import footer from "../global/footer";

const Footer = ({ onLocationChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className=" md:hidden relative pb-5 flex flex-row flex-wrap justify-between"
      sx={{
        backgroundColor: "rgb(230, 230, 230)",
      }}
    >
      {footer.map((element, index) => (
        <FooterElement
          key={index}
          title={element.title}
          elements={element.elements}
          onLocationChange={onLocationChange}
        />
      ))}
    </Box>
  );
};

const FooterElement = ({
  title = "demo",
  elements = [{ label: "test", to: "/", type: "text" }],
  onLocationChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const navigate = useNavigate();

  function handleLocalLocationClick(to) {
    navigate(to);
    onLocationChange(to);
  }

  const elementTypes = {
    text: ({ element }) => <Box>{element.label}</Box>,
    location: ({ element }) => (
      <Box
        onClick={() => handleLocalLocationClick(element.to)}
        className="cursor-pointer"
      >
        {element.label}
      </Box>
    ),
    iconLink: ({ element }) => (
      <Link href={element.href} className="flex flex-row gap-2">
        {element.icon} {element.label}
      </Link>
    ),
  };

  return (
    <Box className="relative p-5 flex flex-col gap-1">
      <Box
        className="w-4 h-4 absolute top-5 left-3 -z-0 "
        sx={{
          backgroundColor: colors.highlight[500],
        }}
      ></Box>
      <Typography className="z-10 relative uppercase pb-2" variant="h3">
        {title}
      </Typography>
      {elements.map((element, index) => (
        <Box key={index}>
          {elementTypes[element.type] ? (
            elementTypes[element.type]({ element: element })
          ) : (
            <Box>ERROR</Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Footer;
