import { Box, Collapse, IconButton, Link, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import NavGroup from "./nav-components/NavGroup";
import { slide as Menu } from "react-burger-menu";
import Topbar from "./Topbar";
import { tokens } from "../theme/theme";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import navItemList from "../global/navItemList";
import { useNavigate } from "react-router-dom";

const MobileMenu = ({ location, onLocationChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [currentRoute, setCurrentRoute] = useState(location);
  useEffect(() => {
    setCurrentRoute(location);
  }, [location]);

  const [open, setOpen] = useState(false);
  function handleLocationChange(route) {
    setOpen(false);
    onLocationChange(route);
  }

  const navigate = useNavigate();

  function handleLogoLocation() {
    navigate("/");
    handleLocationChange("/");
  }
  return (
    <Box
      className="block md:hidden"
      sx={{
        height: "60px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        className="block fixed top-0 left-0 md:hidden overflow-visible z-50 h-14 w-full"
        sx={{
          backgroundColor: colors.contrast,
        }}
      >
        <Box className="h-14 flex py-2 justify-between px-4">
          <Box
            onClick={handleLogoLocation}
            className="flex h-full cursor-pointer"
          >
            <img src="/media/logo.svg" className="h-full" />
          </Box>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
        <Collapse
          in={open}
          className="absolute left-0 top-18 w-full overflow-y-auto"
          sx={{
            boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: colors.contrast,
            minHeight: "-webkit-fill-available",
          }}
        >
          <Box
            className="flex flex-col gap-6 px-8 py-4 overflow-y-auto"
            sx={{
              borderTop: `2px solid ${colors.highlight[500]}`,
            }}
          >
            <Topbar mobile />
            {navItemList.map((group, index) => (
              <NavGroup
                key={index}
                group={group}
                location={currentRoute}
                onLocationChange={handleLocationChange}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default MobileMenu;
