import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Bauleitung from "./sektions/Bauleitung";
import Abbruch from "./sektions/Abbruch";
import Schadstoffsanierung from "./sektions/Schadstoffsanierung";
import Gutachten from "./sektions/Gutachten";
import Entkernung from "./sektions/Entkernung";
import HeroSektion from "./sektions/HeroSektion";
import { useLocation } from "react-router-dom";
import Partner from "./sektions/Partner";
import SägenBohren from "./sektions/SägenBohren";
import Tiefbau from "./sektions/Tiefbau";

const Home = ({ onLocationChange }) => {
  const heroRef = useRef(null);
  const bauleitungRef = useRef(null);
  const abbruchRef = useRef(null);
  const schadstoffsanierungRef = useRef(null);
  const gutachtenRef = useRef(null);
  const entkernungRef = useRef(null);
  const sägenBohrenRef = useRef(null);
  const partnerRef = useRef(null);
  const tiefbauRef = useRef(null);

  const scrollElements = [
    {
      to: "/",
      ref: heroRef,
      element: <HeroSektion />,
      className: "snap-center h-full overflow-y-auto",
    },
    {
      to: "/bauleitung",
      ref: bauleitungRef,
      element: <Bauleitung />,
    },
    {
      to: "/abbruch",
      ref: abbruchRef,
      element: <Abbruch />,
    },
    {
      to: "/entkernung",
      ref: entkernungRef,
      element: <Entkernung />,
    },
    {
      to: "/schadstoffsanierung",
      ref: schadstoffsanierungRef,
      element: <Schadstoffsanierung />,
    },

    {
      to: "/gutachten",
      ref: gutachtenRef,
      element: <Gutachten />,
    },
    {
      to: "/bohren",
      ref: sägenBohrenRef,
      element: <SägenBohren />,
    },
    {
      to: "/tiefbau",
      ref: tiefbauRef,
      element: <Tiefbau />,
    },
    {
      to: "/partner",
      ref: partnerRef,
      element: <Partner />,
    },
  ];

  function findRefByTo(to) {
    const foundElement = scrollElements.find((element) => element.to === to);
    return foundElement ? foundElement.ref : null;
  }

  const location = useLocation();
  useEffect(() => {
    const targetRef = findRefByTo(location.pathname);
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  // snapping logic
  useEffect(() => {
    const handleScroll = () => {
      // Find the ref that is currently snapping
      let snappingRef = null;
      let minDistance = Number.MAX_VALUE;

      scrollElements.forEach((element) => {
        if (element.ref.current) {
          const boundingRect = element.ref.current.getBoundingClientRect();
          const distance = Math.abs(boundingRect.top);
          if (distance < minDistance) {
            minDistance = distance;
            snappingRef = element.ref;
          }
        }
      });

      // Set the currently snapping ref in state
      onLocationChange(snappingRef.current.getAttribute("to"));
    };

    const main = document.getElementById("main");

    // Attach the scroll event listener
    main.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      main.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {scrollElements.map((element, index) => (
        <Box
          key={index}
          ref={element.ref}
          to={element.to}
          className={
            element.className
              ? element.className
              : "snap-center h-auto min-h-full xl:h-full xl:overflow-y-auto"
          }
        >
          {element.element}
        </Box>
      ))}
    </>
  );
};

export default Home;
