import { Box } from "@mui/material";
import NavItem from "./NavItem";

const NavGroup = ({ group, location, onLocationChange }) => {
  return (
    <Box className="flex flex-col gap-1">
      {group.items.map((item, index) => (
        <NavItem
          key={index}
          onLocationChange={onLocationChange}
          item={item}
          active={Boolean(location === item.route)}
        />
      ))}
    </Box>
  );
};

export default NavGroup;
