const cookiesObject = {
  consent: {
    title: "Zustimmung",
    text: [
      {
        variant: "h3",
        text: "Diese Webseite verwendet Cookies",
      },
      {
        variant: "body",
        text: "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.",
      },
    ],
  },
  details: {
    title: "Details",
    elements: [
      {
        title: "Notwendig",
        text: "Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.",
        key: "necessary",
        cookies: [
          {
            title: "Cookie",
            href: "/datenschutz",
            cookieList: [
              {
                cookieName: "Cookieeinstellungen",
                runtime: "1 Jahr",
                type: "HTML",
                description:
                  "Speichern der Cookieeinstellungen, um eine erneute Abfrage zu vermeiden und so die Nutzererfahrung zu verbessern.",
              },
            ],
          },
        ],
      },
      {
        title: "Präferenzen",
        text: "Präferenz-Cookies ermöglichen einer Webseite sich an Informationen zu erinnern, die die Art beeinflussen, wie sich eine Webseite verhält oder aussieht, wie z. B. Ihre bevorzugte Sprache oder die Region in der Sie sich befinden.",
        key: "preferences",
        cookies: [],
      },
      {
        title: "Statistiken",
        text: "Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.",
        key: "statistics",
        cookies: [],
      },
      {
        title: "Marketing",
        text: "Marketing-Cookies werden verwendet, um Besuchern auf Webseiten zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.",
        key: "marketing",
        cookies: [],
      },
      {
        title: "Nicht klassifiziert",
        text: "Nicht klassifizierte Cookies sind Cookies, die wir gerade versuchen zu klassifizieren, zusammen mit Anbietern von individuellen Cookies.",
        key: "notClassified",
        cookies: [],
      },
    ],
  },
  description: {
    title: "Über Cookies",
    dataPrivacy: {
      title: "Datenschutzerklärung",
      href: "/datenschutz",
    },
    text: [
      {
        variant: "body",
        text: "Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten.",
      },
      {
        variant: "body",
        text: "Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis. Dies bedeutet, dass als notwendig kategorisierte Cookies auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO verarbeitet werden. Alle anderen nicht notwendigen Cookies, d. h. diejenigen aus den Kategorien Präferenzen und Marketing, werden auf der Grundlage von Art. 6 Abs. 1 lit. a  DSGVO verarbeitet.",
      },
      {
        variant: "body",
        text: "Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.",
      },
      {
        variant: "body",
        text: "Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern oder widerrufen.",
      },
      {
        variant: "body",
        text: "Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber, wer wir sind, wie Sie uns kontaktieren können und wie wir personenbezogene Daten verarbeiten.",
      },
      {
        variant: "body",
        text: "Bitte geben Sie Ihre Einwilligungs-ID und das Datum an, wenn Sie uns bezüglich Ihrer Einwilligung kontaktieren.",
      },
    ],
  },
};

export default cookiesObject;
