import { Box, Typography, useTheme } from "@mui/material";
import times from "../../global/times";
import { tokens } from "../../theme/theme";

const Times = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className=" w-full flex items-center justify-center"
      sx={{
        height: "400px",
      }}
    >
      <Box className="flex flex-col gap-4 relative">
        <Box
          className="w-full h-full absolute -top-6 -left-6 -z-10"
          sx={{ backgroundColor: colors.card }}
        ></Box>
        <Box className="p-4" sx={{ backgroundColor: colors.card }}>
          <Typography variant="h2" className="uppercase">
            Öffnungszeiten
          </Typography>
          <table>
            <tr>
              {times.map((time, index) => (
                <TimeRow time={time} key={index} />
              ))}
            </tr>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

const TimeRow = ({ time }) => {
  return (
    <tr>
      <td className="w-40 p-1">
        <Typography variant="h4">{time.day}</Typography>
      </td>
      <td>
        <Typography variant="h4">{time.text}</Typography>
      </td>
    </tr>
  );
};

export default Times;
