import { Box, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavGroup from "./nav-components/NavGroup";
import Socials from "./nav-components/Socials";
import navItemList from "../global/navItemList";
import { useNavigate } from "react-router";
import MadeBy from "./nav-components/MadeBy";

const Sidebar = ({ location, onLocationChange }) => {
  const [currentRoute, setCurrentRoute] = useState(location);
  useEffect(() => {
    setCurrentRoute(location);
  }, [location]);

  const navigate = useNavigate();

  function handleLogoLocation() {
    navigate("/");
    onLocationChange("/");
  }

  return (
    <Box
      className="hidden md:flex h-full flex-col gap-6 pb-8 overflow-y-auto"
      sx={{ width: "400px" }}
    >
      <Box className="p-8 flex flex-col gap-4">
        <img
          onClick={handleLogoLocation}
          src="/media/logo.svg"
          className="w-full cursor-pointer"
        />

        <Typography variant="h4">
          Heimathafen für Ihren Projekterfolg
        </Typography>
      </Box>
      <Box className="flex flex-col justify-between px-4 h-full">
        {navItemList.map((group, index) => (
          <NavGroup
            key={index}
            group={group}
            location={currentRoute}
            onLocationChange={onLocationChange}
          />
        ))}
      </Box>
      <Socials />
    </Box>
  );
};

export default Sidebar;
