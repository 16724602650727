const partners = [
  {
    url: "https://das-innovations.de",
    logo: "/media/partner/daslogo.svg",
    name: "DAS Innovations",
    description: "Websites & Webapps",
  },
  {
    url: "https://pfleger-business.de",
    logo: "/media/partner/pflegerConsulting.svg",
    name: "Business Consulting Pfleger",
    description: "Unternehmensberatung",
  },
  {
    url: "https://casa-gradia.de",
    logo: "/media/partner/casalogo.png",
    name: "Casa Gradia",
    description: "Immobilien",
  },
  {
    url: "https://www.affeldschaefer.de",
    logo: "/media/partner/affeldlogo.svg",
    name: "Affeld Schaefer",
    description: "Säge- & Bohrtechnik",
  },
  {
    url: "https://sg-fenster.de",
    logo: "/media/partner/sgfensterlogo.svg",
    name: "Simsek und Gross Fenster und Türen",
    description: "Fenster & Türen",
  },
  {
    url: "https://korz-abbruchservice.de",
    logo: "/media/partner/korzlogo.svg",
    name: "D.Korz Bau- & Abbruchservice",
    description: "Bau- & Abbruch",
  },
];

export default partners;
