const certificats = [
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-02.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-02.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-03.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-03.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-06.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-06.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-10.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-10.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-11.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-11.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-12.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-12.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-13.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-13.jpg",
    },
  },
  {
    title: "",
    src: {
      webp: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-14.webp",
      jpg: "/media/certifications/PFLEGER BAUSERVICE ZERTIFIKAT-14.jpg",
    },
  },
];

export default certificats;
