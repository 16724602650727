import certificats from "../../global/certificats";
import ImageWithFallback from "../../components/ImageWithFallback";
import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Certificats = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      className="h-96 mt-8 mb-10"
      sx={{
        "& button": {
          margin: "0",
        },
        "& .react-multi-carousel-dot-list": {
          display: "flex",
          gap: "10px",
          marginTop: "20px",
        },
        "& .react-multi-carousel-list ": {
          paddingBottom: "20px",
        },
      }}
    >
      <Typography variant="h2" className="uppercase pb-8 px-4 md:px-0">
        Zertifiziert
      </Typography>
      <Carousel
        infinite
        centerMode
        responsive={responsive}
        showDots={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        transitionDuration={1000}
        keyBoardControl={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {certificats.map((cert, index) => (
          <CarouselItem key={index} cert={cert} />
        ))}
      </Carousel>
    </Box>
  );
};

const CarouselItem = ({ cert }) => {
  return (
    <ImageWithFallback
      className="object-contain w-52"
      sx={{ aspectRatio: "1/1.41" }}
      webp={cert.src.webp}
      jpg={cert.src.jpg}
      alt={cert.title}
    />
  );
};

export default Certificats;
