import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";

const HeroTitel = ({ title, upperText }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="relative flex flex-col gap-0"
      sx={{
        background:
          "linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 80%)",
        marginBottom: "20vh",
        marginLeft: "8%",
        zIndex: "10",
        color: colors.contrast,
        padding: "3vw 30px",
        textTransform: "uppercase",
        textShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
        maxWidth: "50vw",
        fontSize: "5vw",
        lineHeight: "5vw",
        "@media (max-width: 767px)": {
          fontSize: "9vw",
          lineHeight: "9vw",
          marginBottom: "0vh",
          marginTop: "10vh",
          maxWidth: "unset",
          maxWidth: "unset",
          padding: "8vw 30px",
        },
        "::before, ::after": {
          width: "80%",
          height: "5px",
          content: '" "',
          backgroundColor: colors.highlight[500],
          display: "block",
          position: "absolute",
          left: "0",
        },
        "::before": {
          top: "0",
        },
        "::after": {
          bottom: "0",
        },
      }}
    >
      {upperText && (
        <Box
          sx={{
            color: colors.contrast,
            fontSize: "1.5vw",
            lineHeight: "1.5vw",
            textTransform: "uppercase",
            textShadow: "0px 0px 3px rgba(0, 0, 0, 0.5)",
            "@media (max-width: 767px)": {
              fontSize: "4vw",
              lineHeight: "5vw",
            },
          }}
        >
          {upperText}
        </Box>
      )}
      {title}
    </Box>
  );
};

export default HeroTitel;
