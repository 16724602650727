import { Box, Link, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../theme/theme";
import contact from "../global/contact";

const Topbar = ({ mobile = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className={`px-4 py-4 md:py-6 flex-row justify-center items-center ${
        mobile ? "flex md:hidden" : "hidden md:flex"
      }`}
      sx={{ backgroundColor: colors.contrast }}
    >
      <Box
        className="w-full md:w-1/2 flex flex-row justify-between"
        sx={{ minWidth: "310px" }}
      >
        {contact.map((item, index) => (
          <ContactLink
            key={index}
            href={item.href}
            icon={item.icon}
            label={item.label}
          />
        ))}
      </Box>
    </Box>
  );
};

const ContactLink = ({ href, icon, label }) => {
  return (
    <Link href={href}>
      <Box className="flex flex-row gap-2 justify-start items-center">
        {icon}
        {label}
      </Box>
    </Link>
  );
};

export default Topbar;
