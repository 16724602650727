import { useEffect, useState } from "react";

const ImageWithFallback = ({
  webp,
  jpg,
  alt = "",
  className = "",
  sx = {},
}) => {
  const [supportsWebP, setSupportsWebP] = useState(true);

  useEffect(() => {
    checkWebpSupport();
  }, []);

  function checkWebpSupport() {
    const img = new Image();

    img.onload = () => {
      setSupportsWebP(true);
      console.log("TRUE");
    };

    img.onerror = () => {
      setSupportsWebP(false);
      console.log("FALSE");
    };

    img.src = webp;
  }

  return (
    <img
      style={sx}
      src={supportsWebP ? webp : jpg}
      alt={alt}
      className={className}
    />
  );
};

export default ImageWithFallback;
