import { Box } from "@mui/material";
import InfoSection from "../../../components/InfoSection";
import InfoGalery from "../../../components/InfoGalery";

const Abbruch = () => {
  const bullets = [
    "Abbruch aller Art",
    "Kontrollierter Rückbau",
    "Total / Handabbruch",
    "Demontage",
    "Selektiver Rückbau",
    "Schadstofferkundung",
  ];
  const pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-01.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-01.jpg",
    },
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-06.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-06.jpg",
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={2}
        title="Abbruch"
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};

export default Abbruch;
