import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import TitlePic from "../../components/TitlePic";
import ImpressumContent from "../../global/Impressum";

const Impressum = () => {
  const impressumRef = useRef(null);
  useEffect(() => {
    if (impressumRef)
      impressumRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <Box ref={impressumRef}>
      <TitlePic
        title="Impressum"
        upperText="Rechtliche Informationen"
        src={{
          webp: "/media/title/PFLEGER BAUSERVICE TITLE-6.webp",
          jpg: "/media/title/PFLEGER BAUSERVICE TITLE-6.jpg",
        }}
      />
      <ImpressumContent />
    </Box>
  );
};

export default Impressum;
