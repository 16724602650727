import { Box } from "@mui/material";
import InfoSection from "../../../components/InfoSection";
import InfoGalery from "../../../components/InfoGalery";

const Gutachten = () => {
  const bullets = ["Bauschadengutachten", "Wert Kurzgutachten"];
  const pictures = [
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-09.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-09.jpg",
    },
    {
      webp: "/media/info/PFLEGER BAUSERVICE INFO-11.webp",
      jpg: "/media/info/PFLEGER BAUSERVICE INFO-11.jpg",
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={5}
        title="Gutachten"
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};

export default Gutachten;
